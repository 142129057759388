import clsx from "clsx";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import LoadingButton from "../../components/buttons/loadingButton";
import { RouteStatus } from "../../models/enums/routeStatus";
import { Route } from "../../models/routes/route";
import routesService from "../../services/routesService";
import androidService from "../../services/mobile/androidService";
import { useAppSelector } from "../../hooks";

const ACTIVE_STATUSES = [RouteStatus.InWork, RouteStatus.Finished, RouteStatus.WaitingForRouteSheet];

type Props = {
    route: Route;
    onRouteChange: (route: Route) => void;
};

const getRouteLink = (route: Route) => `/routes/${route.orderId}`;

const RouteActions: React.FC<Props> = ({ route, onRouteChange }) => {
    const [beginActionInProgress, setBeginActionInProgress] = useState(false);

    const navigate = useNavigate();
    const accessToken = useAppSelector((state) => state.auth.accessToken)!;

    const className = "mt-2";
    const buttonClassName = "w-100";

    const handleBeginButtonClick = async () => {
        setBeginActionInProgress(true);

        const locationIsStarted = androidService.startLocationService(route.orderId, accessToken);
        if (locationIsStarted) {
            const { isOk } = await routesService.setStatus(route.orderId, RouteStatus.InWork);
            isOk && navigate(getRouteLink(route));
        }

        setBeginActionInProgress(false);
    };

    return (
        <>
            <Actions active={route.status === RouteStatus.Confirmed}>
                <Row className={className}>
                    <Col>
                        <LoadingButton
                            caption="Начать"
                            loading={beginActionInProgress}
                            disabled={beginActionInProgress}
                            className={buttonClassName}
                            color="primary"
                            onClick={handleBeginButtonClick}
                        />
                    </Col>
                </Row>
            </Actions>
            <Actions active={ACTIVE_STATUSES.includes(route.status)}>
                <Link className={clsx("btn btn-primary", className, buttonClassName)} to={getRouteLink(route)}>
                    Маршрут
                </Link>
            </Actions>
        </>
    );
};

type ActionsProps = {
    active: boolean;
    children: React.ReactNode;
};

const Actions: React.FC<ActionsProps> = ({ active, children }) => {
    return active ? <>{children}</> : null;
};

export default RouteActions;
