import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import LoadingContainer from "../../components/containers/loadingContainer";
import { RoutePointStatus } from "../../models/enums/routePointStatus";
import { RoutePoint } from "../../models/routes/routePoint";
import routePointsService from "../../services/routePointsService";
import routesService from "../../services/routesService";
import CheckList from "./checkList";
import RoutePointView from "./routPointView";
import { ConfirmRouteSheetCard } from "./nextWorkCards/confirmRouteSheetCard";
import { NextWorksId, NextWorksIds, Route } from "../../models/routes/route";
import { RouteStatus } from "../../models/enums/routeStatus";
import androidService from "../../services/mobile/androidService";
import { SignedDocumentsCard } from "./nextWorkCards/signedDocumentsCard";

export default function RoutePointsPage() {
    const { routeId } = useParams();

    const [route, setRoute] = useState<Route | null>(null);
    const [routePoints, setRoutePoints] = useState<RoutePoint[]>([]);
    const [openPoint, setOpenPoint] = useState<RoutePoint | null>(null);

    const setCurrentPointStatus = (points: RoutePoint[]) => {
        // Делаем доступной следующую точку маршрута
        const nextPoint = points.find((p) => p.status === RoutePointStatus.Open);
        if (nextPoint !== undefined) nextPoint.status = RoutePointStatus.Current;

        setRoutePoints(points);
    };

    const loadRoute = async () => {
        const routeResponse = await routesService.getRoute(routeId!);
        const newPoints = routeResponse.isOk ? routeResponse.data!.points : [];
        routeResponse.isOk && setRoute(routeResponse.data!);
        setCurrentPointStatus(newPoints);
    };

    const handlePointChange = (point: RoutePoint, index: number) => {
        const newPoints = [...routePoints];
        newPoints[index] = point;
        setCurrentPointStatus(newPoints);
    };

    const handleDoneButtonClick = async () => {
        const index = routePoints.findIndex((p) => p.orderId === openPoint!.orderId);
        const point = routePoints[index];

        const { isOk, data } = await routePointsService.setStatus(point.orderId, RoutePointStatus.Closed);
        if (isOk) {
            setOpenPoint(data);
            handlePointChange(data!, index);
        }

        // Если это первая точка, то ставим родительский заказ в статус ожидает сдачи маршрутного листа
        if (index === 0 && route!.nextWorksCheckListId && NextWorksIds.includes(route!.nextWorksCheckListId)) {
            const { isOk: isUpdateRoute, data: updatedRoute } = await routesService.setStatus(
                point.orderId,
                RouteStatus.WaitingForRouteSheet,
            );
            isUpdateRoute && setRoute({ ...route!, status: updatedRoute!.status });
        }

        // Если это последняя точка, то завершаем отслеживание маршрута
        if (index === routePoints.length - 1) {
            androidService.stopLocationService();
        }

        handleCloseCheckList();
    };

    const handleOpenPoint = (point: RoutePoint) => {
        setOpenPoint(point);
    };

    const handleCloseCheckList = () => setOpenPoint(null);

    const handleChangeOpenPoint = (point: RoutePoint) => {
        setOpenPoint(point);
        const index = routePoints.findIndex((p) => p.orderId === point.orderId);
        const newPoints = [...routePoints];
        newPoints[index] = point;
        setRoutePoints(newPoints);
    };

    return (
        <div>
            {!openPoint && <h6 className="text-center fw-bold">Маршрут - {routeId}</h6>}
            <LoadingContainer onLoading={loadRoute} caption="Загрузка маршрута">
                {openPoint ? (
                    <CheckList
                        routePoint={openPoint}
                        isFirstPoint={openPoint.orderId === routePoints[0].orderId}
                        onDoneButtonClick={handleDoneButtonClick}
                        onClose={handleCloseCheckList}
                        onChangePoint={handleChangeOpenPoint}
                    />
                ) : route ? (
                    <>
                        {routePoints.map((point, index) => (
                            <RoutePointView
                                key={index}
                                routePoint={point}
                                number={index + 1}
                                onOpenPoint={() => handleOpenPoint(point)}
                            />
                        ))}
                        {/*TODO: Объединить в универсальный компонент*/}
                        {route.nextWorksCheckListId === NextWorksId.RouteSheetPhoto &&
                            routePoints.at(-1)?.status === RoutePointStatus.Closed && (
                                <ConfirmRouteSheetCard route={route} onChangeRoute={(route) => setRoute(route)} />
                            )}
                        {route.nextWorksCheckListId === NextWorksId.SignedDocumentsPhotoForBicycles &&
                            routePoints.at(-1)?.status === RoutePointStatus.Closed && (
                                <SignedDocumentsCard route={route} onChangeRoute={(route) => setRoute(route)} />
                            )}
                    </>
                ) : (
                    <div>Маршрут {routeId} не найден</div>
                )}
            </LoadingContainer>
            {!openPoint && (
                <Link to="/" className="btn btn-secondary btn-lg w-100 mt-5 mb-4">
                    К списку маршрутов &lt;&lt;
                </Link>
            )}
        </div>
    );
}
