import clsx from "clsx";
import { PropsWithChildren } from "react";
import attachmentsService from "../../../services/attachmentsService";
import routesService from "../../../services/routesService";
import { RouteStatus } from "../../../models/enums/routeStatus";
import ListItemCard from "../../../components/containers/listItemCard";
import FileInput from "../../../components/inputs/fileInput";
import ImageGallery from "../../../components/containers/imageGallery";
import { Route } from "../../../models/routes/route";
import { FileInfo } from "../../../models/routes/fileInfo";

export type NextWorkCardProps = PropsWithChildren & {
    route: Route;
    caption: string;
    onChangeRoute: (route: Route) => void;
};

export const NextWorkCard = ({ route, caption, onChangeRoute, children }: NextWorkCardProps) => {
    const handleAddPhotos = async function* (files: File[]) {
        const addedFiles: FileInfo[] = [];

        for (const file of files) {
            const fileName = `МЛ_${file.name}`;
            const { isOk, data: fileInfo } = await attachmentsService.upload(file, route.orderId, undefined, fileName);

            if (!isOk || !fileInfo) return;

            fileInfo.data = file;
            fileInfo.name = file.name;

            addedFiles.push(fileInfo);

            const newRoute = { ...route };
            newRoute.files = newRoute.files ? [...newRoute.files, ...addedFiles] : [...addedFiles];

            if (route.status !== RouteStatus.Finished) {
                const { isOk, data } = await routesService.setStatus(route.orderId, RouteStatus.Finished);
                if (isOk && data) newRoute.status = data.status;
            }

            onChangeRoute(newRoute);

            yield fileInfo.guid;
        }
    };

    const handleRemovePhotos = async (files: FileInfo[]) => {
        let actualFiles = [...route.files!];

        for (const file of files) {
            const { isOk } = await attachmentsService.remove(file.guid);
            if (!isOk) continue;

            actualFiles = actualFiles?.filter((f) => f.guid !== file.guid);

            const newRoute = { ...route, files: actualFiles };

            if (route.status === RouteStatus.Finished && actualFiles.length === 0) {
                const { isOk, data } = await routesService.setStatus(route.orderId, RouteStatus.WaitingForRouteSheet);
                if (isOk && data) newRoute.status = data.status;
            }

            onChangeRoute(newRoute);
        }
    };

    const routeFinished = route.status === RouteStatus.Finished;

    return (
        <>
            <div className="my-2 mt-4">
                <div className="fw-bold text-center">{caption}</div>
            </div>
            <ListItemCard className={clsx("border-start border-5", routeFinished ? "border-success" : "border-warning")}>
                {children}
                <FileInput
                    files={route.files ?? []}
                    accept="image/*"
                    multiple={true}
                    className="mt-2"
                    caption="Прикрепить"
                    buttonColor="primary"
                    onAddFiles={handleAddPhotos}
                    onRemoveFiles={handleRemovePhotos}
                    renderFileItems={(files, onRemoveFiles) => <ImageGallery images={files} onRemoveFiles={onRemoveFiles} />}
                />
            </ListItemCard>
        </>
    );
};
